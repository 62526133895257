import { storeToRefs } from 'pinia'

import { useInfluencersStore } from '~/stores/influencers'
import { useUserFavoritesStore } from '~/stores/userFavorites'

export default defineNuxtRouteMiddleware(async () => {
  const { $localePath, $pinia } = useNuxtApp()

  const { buckets: BUCKETS } = storeToRefs(useUserFavoritesStore($pinia))

  const { FETCH_SET: FETCH_INFLUENCER_SET } = useInfluencersStore($pinia)

  await FETCH_INFLUENCER_SET(
    BUCKETS.value.reduce(
      (acc, bucket) => [...acc, ...bucket.influencers],
      [] as number[],
    ),
  )
})
